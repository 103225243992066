import React, { useState } from "react";
import { ACCOUNT_SETTINGS_ROUTES } from "@/utils/mapping";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { cn } from "@/lib/utils";
import { BurgerIcon } from "@/components/Icons/BurgerIcon";
import { CrossIcon } from "@/components/Icons/CrossIcon";
import { Badge } from "@/components/ui/badge";
import { useLocation } from "wouter";
import { AuthMachineContext } from "@/store/machine/authMachine/authMachine";
import { AuthEventType } from "@/types/auth";
import { LogOutIcon } from "@/components/Icons/LogOutIcon";
import { LinkIcon } from "@/components/Icons/LinkIcon";

export const MobileNav: React.FC = () => {
  const [location, setLocation] = useLocation();
  const [open, setOpen] = useState(false);
  const authActorRef = AuthMachineContext.useActorRef();

  const handleLogout = async () => {
    authActorRef.send({
      type: AuthEventType.LOGOUT
    });
  };

  return (
    <>
      <HoverCard open={open} openDelay={300}>
        <HoverCardTrigger
          onClick={() => setOpen(!open)}
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
        >
          <div
            className={cn(
              "flex h-9 w-9 cursor-pointer items-center border bg-gray-dark-1700 p-2.5",
              open ? "border-gray-dark-1200" : "border-gray-dark-900"
            )}
          >
            {open ? (
              <CrossIcon className="h-4 w-4 text-gray-dark-1000" />
            ) : (
              <BurgerIcon className="h-4 w-4 text-gray-dark-1000" />
            )}
          </div>
          <HoverCardContent
            className="mt-[-8px] border-none bg-transparent p-0 pt-4 md:w-[415px]"
            align="end"
          >
            <div className="flex w-full flex-col items-center justify-center rounded border border-gray-dark-900 bg-gray-dark-1400 px-4 md:w-[415px]">
              {ACCOUNT_SETTINGS_ROUTES.map(({ href, disabled, label }, index) => {
                const isActive = location.includes(href);
                return (
                  <div
                    key={`mobile_nav_settings_route-${index}`}
                    onClick={() => {
                      setLocation(href);
                      setOpen(false);
                    }}
                    className={cn(
                      "w-full px-3 py-3 text-center text-sm",
                      isActive ? "border-b-2 border-white text-white" : "text-gray-dark-1000",
                      disabled ? "pointer-events-none flex justify-center gap-4" : "cursor-pointer"
                    )}
                  >
                    <span>{label}</span>
                    {disabled && <Badge>Coming Soon</Badge>}
                  </div>
                );
              })}
              <a
                href="https://docs.io.net/"
                target="_blank"
                className="mb-4 flex flex-row items-center gap-2 text-sm text-gray"
              >
                Io Documentation
                <LinkIcon className="h-[13px]" />
              </a>
              <div
                key="mobile_nav_route_logout"
                onClick={handleLogout}
                className={cn(
                  "mb-3 flex w-full cursor-pointer justify-center px-3 text-center text-sm text-red-light-800"
                )}
              >
                <LogOutIcon className="mr-1 h-[18px]" />
                <span>Sign Out</span>
              </div>
            </div>
          </HoverCardContent>
        </HoverCardTrigger>
      </HoverCard>
    </>
  );
};

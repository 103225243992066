import { IconProps } from ".";

export const IoNetIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 67 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.3265 0H2.45898L5.67328 2.35693H15.3265C15.7012 2.35693 16.009 2.68526 16.009 3.0851V4.8441L18.2176 6.46369V3.0851C18.2176 1.38848 16.9164 0 15.3265 0Z"
        fill="currentColor"
      />
      <path
        d="M8.56401 9.99995H21.4316L18.2172 7.643H8.56401C8.18929 7.643 7.88162 7.31468 7.88162 6.91483V5.15584L5.67285 3.53625V6.91483C5.67285 8.61513 6.97062 9.99995 8.56401 9.99995Z"
        fill="currentColor"
      />
      <path d="M22.8037 7.64294H25.0125V9.99988H22.8037V7.64294Z" fill="currentColor" />
      <path
        d="M36.0389 0C37.6322 0 38.9301 1.38666 38.9301 3.0851V10H36.7213V3.0851C36.7213 2.68526 36.4136 2.35693 36.0389 2.35693H29.2768C28.9021 2.35693 28.5945 2.68526 28.5945 3.0851V10H26.3857V3.0851C26.3857 1.38482 27.6834 0 29.2768 0H36.0389Z"
        fill="currentColor"
      />
      <path
        d="M52.8471 0V2.35693H43.1939C42.8192 2.35693 42.5115 2.68526 42.5115 3.0851V3.82795H40.3027V3.0851C40.3027 1.38482 41.6004 0 43.1939 0H52.8471ZM43.1939 3.82795H52.8471V6.17022H43.1939C42.8192 6.17022 42.5115 6.51321 42.5115 6.91306C42.5115 7.31292 42.8192 7.64125 43.1939 7.64125H52.8471V9.99818H43.1939C41.6004 9.99818 40.3027 8.61152 40.3027 6.91306C40.3027 5.19993 41.6004 3.82795 43.1939 3.82795Z"
        fill="currentColor"
      />
      <path
        d="M61.537 2.88519V10H59.3282V3.0851C59.3282 2.68526 59.0205 2.35693 58.6458 2.35693H54.1611V0H58.6458C60.1722 0 61.4303 1.27293 61.5249 2.88702H61.537V2.88519ZM61.537 2.35693V0H66.7056V2.35693H61.537Z"
        fill="currentColor"
      />
      <path d="M2.45875 3.53467H0.25V10.0002H2.45875V3.53467Z" fill="currentColor" />
      <path d="M0.25 0V2.01945L2.37969 3.58217L2.45875 3.53631V0H0.25Z" fill="currentColor" />
    </svg>
  );
};

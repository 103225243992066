import { IconProps } from "@/components/Icons/index.ts";

export const LinkIcon: React.FC<IconProps> = (props) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="external-link" clipPath="url(#clip0_2256_20371)">
      <g id="Vector">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.33333 4.5C3.11232 4.5 2.90036 4.5878 2.74408 4.74408C2.5878 4.90036 2.5 5.11232 2.5 5.33333V12.6667C2.5 12.8877 2.5878 13.0996 2.74408 13.2559C2.90036 13.4122 3.11232 13.5 3.33333 13.5H10.6667C10.8877 13.5 11.0996 13.4122 11.2559 13.2559C11.4122 13.0996 11.5 12.8877 11.5 12.6667V8.66667C11.5 8.39052 11.7239 8.16667 12 8.16667C12.2761 8.16667 12.5 8.39052 12.5 8.66667V12.6667C12.5 13.1529 12.3068 13.6192 11.963 13.963C11.6192 14.3068 11.1529 14.5 10.6667 14.5H3.33333C2.8471 14.5 2.38079 14.3068 2.03697 13.963C1.69315 13.6192 1.5 13.1529 1.5 12.6667V5.33333C1.5 4.8471 1.69315 4.38079 2.03697 4.03697C2.38079 3.69315 2.8471 3.5 3.33333 3.5H7.33333C7.60948 3.5 7.83333 3.72386 7.83333 4C7.83333 4.27614 7.60948 4.5 7.33333 4.5H3.33333Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.5 2C9.5 1.72386 9.72386 1.5 10 1.5H14C14.2761 1.5 14.5 1.72386 14.5 2V6C14.5 6.27614 14.2761 6.5 14 6.5C13.7239 6.5 13.5 6.27614 13.5 6V2.5H10C9.72386 2.5 9.5 2.27614 9.5 2Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.3536 1.64645C14.5488 1.84171 14.5488 2.15829 14.3536 2.35355L7.02022 9.68689C6.82496 9.88215 6.50838 9.88215 6.31311 9.68689C6.11785 9.49163 6.11785 9.17504 6.31311 8.97978L13.6464 1.64645C13.8417 1.45118 14.1583 1.45118 14.3536 1.64645Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.33333 4.5C3.11232 4.5 2.90036 4.5878 2.74408 4.74408C2.5878 4.90036 2.5 5.11232 2.5 5.33333V12.6667C2.5 12.8877 2.5878 13.0996 2.74408 13.2559C2.90036 13.4122 3.11232 13.5 3.33333 13.5H10.6667C10.8877 13.5 11.0996 13.4122 11.2559 13.2559C11.4122 13.0996 11.5 12.8877 11.5 12.6667V8.66667C11.5 8.39052 11.7239 8.16667 12 8.16667C12.2761 8.16667 12.5 8.39052 12.5 8.66667V12.6667C12.5 13.1529 12.3068 13.6192 11.963 13.963C11.6192 14.3068 11.1529 14.5 10.6667 14.5H3.33333C2.8471 14.5 2.38079 14.3068 2.03697 13.963C1.69315 13.6192 1.5 13.1529 1.5 12.6667V5.33333C1.5 4.8471 1.69315 4.38079 2.03697 4.03697C2.38079 3.69315 2.8471 3.5 3.33333 3.5H7.33333C7.60948 3.5 7.83333 3.72386 7.83333 4C7.83333 4.27614 7.60948 4.5 7.33333 4.5H3.33333Z"
          fill="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.5 2C9.5 1.72386 9.72386 1.5 10 1.5H14C14.2761 1.5 14.5 1.72386 14.5 2V6C14.5 6.27614 14.2761 6.5 14 6.5C13.7239 6.5 13.5 6.27614 13.5 6V2.5H10C9.72386 2.5 9.5 2.27614 9.5 2Z"
          fill="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.3536 1.64645C14.5488 1.84171 14.5488 2.15829 14.3536 2.35355L7.02022 9.68689C6.82496 9.88215 6.50838 9.88215 6.31311 9.68689C6.11785 9.49163 6.11785 9.17504 6.31311 8.97978L13.6464 1.64645C13.8417 1.45118 14.1583 1.45118 14.3536 1.64645Z"
          fill="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_2256_20371">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

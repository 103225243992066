import { CrossIcon } from "@/components/Icons/CrossIcon";

type WarningBanner = {
  isOpen: boolean;
  message?: React.ReactNode;
  onClose?: () => void;
};

export const WarningBanner: React.FC<WarningBanner> = ({ isOpen, message, onClose }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed top-0 z-[9999999] flex w-full flex-row items-center gap-5 bg-gray-dark-500 px-5 py-3 text-sm text-white">
      <span className="flex-1 text-center">{message}</span>
      <CrossIcon className="h-[16px] cursor-pointer text-white" onClick={onClose} />
    </div>
  );
};

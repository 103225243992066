export enum AnalyticsEventType {
  CREATE_CLUSTER_START = "CREATE_CLUSTER_START",
  COMPLETED_CLUSTER_CREATION_STEP = "COMPLETED_CLUSTER_CREATION_STEP",
  CLUSTER_TYPE_SELECTED = "CLUSTER_TYPE_SELECTED",
  CLUSTER_SUPPLIER_SELECTED = "CLUSTER_SUPPLIER_SELECTED",
  CLUSTER_SECURITY_COMPLIANCE_SELECTED = "CLUSTER_SECURITY_COMPLIANCE_SELECTED",
  CLUSTER_LOCATIONS_SELECTED = "CLUSTER_LOCATIONS_SELECTED",
  CLUSTER_CONNECTIVITY_TIER_SELECTED = "CLUSTER_CONNECTIVITY_TIER_SELECTED",
  CLUSTER_PROCESSOR_SELECTED = "CLUSTER_PROCESSOR_SELECTED",
  CLUSTER_BASE_IMAGE_SELECTED = "CLUSTER_BASE_IMAGE_SELECTED",
  CLUSTER_QUANTITY_SELECTED = "CLUSTER_QUANTITY_SELECTED",
  CLUSTER_MASTER_NODE_SELECTED = "CLUSTER_MASTER_NODE_SELECTED",
  CLUSTER_DURATION_SELECTED = "CLUSTER_DURATION_SELECTED",
  CLUSTER_PROCESSOR_QUANTITY_SELECTED = "CLUSTER_PROCESSOR_QUANTITY_SELECTED",
  CLUSTER_PAYMENT_BUTTON_CLICKED = "CLUSTER_PAYMENT_BUTTON_CLICKED",
  CLUSTER_CANCEL_DEPLOYMENT_BUTTON_CLICKED = "CLUSTER_CANCEL_DEPLOYMENT_BUTTON_CLICKED",
  CLUSTER_DEPLOY_SELECTED = "CLUSTER_DEPLOY_SELECTED",
  CLUSTER_DEPLOYED_SUCCESSFULLY = "CLUSTER_DEPLOYED_SUCCESSFULLY",
  CLUSTER_PROCESSOR_FILTER_SELECTED = "CLUSTER_PROCESSOR_FILTER_SELECTED",
  CLUSTER_PROCESSOR_OPTION_SELECTED = "CLUSTER_PROCESSOR_OPTION_SELECTED",
  CLUSTER_LOCATION_SELECT_ALL_CLICKED = "CLUSTER_LOCATION_SELECT_ALL_CLICKED"
}

import env from "@/env";
import * as Sentry from "@sentry/react";
import { isNumericOr } from "./number";

export const initialiseSentry = () => {
  if (!env.FEATUREFLAG_SENTRY_ANALYTICS || !env.SENTRY_DSN) {
    return;
  }
  if (
    env.APP_RELEASE_VERSION === "0.0.0" ||
    window.location.hostname === "localhost" ||
    env.ENVIRONMENT === "development"
  ) {
    return;
  }

  const options = {
    release: `cloud-io-net@${env.APP_RELEASE_VERSION}`,
    environment: "production",
    dsn: env.SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.captureConsoleIntegration({
        levels: ["error"]
      }),
      Sentry.moduleMetadataIntegration(),
      Sentry.contextLinesIntegration(),
      Sentry.globalHandlersIntegration({
        onerror: false,
        onunhandledrejection: false
      })
    ],
    denyUrls: ["https://www.gstatic.com"],
    beforeSend(event, hint) {
      const error = hint.originalException as Error | undefined;

      if (error && error.message) {
        const { message } = error;

        if (message.indexOf("Importing a module script failed") > -1) {
          return null;
        }
      }

      return event;
    },
    // https://docs.sentry.io/platforms/javascript/configuration/options/
    sampleRate: 1.0,

    // Performance Monitoring
    tracesSampleRate: isNumericOr(env.SENTRY_TRACES_SAMPLE_RATE, 1.0),
    // Session Replay
    replaysSessionSampleRate: isNumericOr(env.SENTRY_REPLAYS_SESSION_SAMPLE_RATE, 0.1),
    replaysOnErrorSampleRate: isNumericOr(env.SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE, 1.0)
  } as Sentry.BrowserOptions;

  console.log("Initialised Sentry", options);

  Sentry.init(options);
};

import env from "@/env";
import { waitFor } from ".";
import { executeAPIRequest } from "@/hooks/useAPI";
import { toFriendlyError } from "./api";
import localCookies from "./cookie";

export const setIntercomSettings = async (options: {
  userId: string;
  name?: string;
  email?: string;
  createdAt?: number;
}) => {
  if (!env.INTERCOM_ENABLED) {
    return;
  }

  try {
    await waitFor(() => !!window.Intercom);

    const { name, userId, email, createdAt } = options;
    const userHash = await getUserHash();

    window.intercomSettings = {
      api_base: "https://api-iam.intercom.io",
      app_id: env.INTERCOM_APP_ID,
      name: name, // Full name
      user_id: userId, // a UUID for your user
      user_hash: userHash,
      email: email, // the email for your user
      created_at: createdAt // Signup date as a Unix timestamp
    };

    window.Intercom("boot", window.intercomSettings);
  } catch (e) {
    console.log(e);
  }
};

export const shutdownIntercom = () => {
  try {
    window.Intercom?.("shutdown");
    localCookies.removeItem(INTERCOM_USER_HASH_KEY);
  } catch (e) {
    console.log(e);
  }
};

const getUserHash = async () => {
  const currentHash = localCookies.getItem(INTERCOM_USER_HASH_KEY);
  if (currentHash) {
    return currentHash;
  }

  try {
    const response = await executeAPIRequest<string>({
      method: "get",
      url: `/io-intercom/generate-intercom-user-hash`
    });

    localCookies.setItem(INTERCOM_USER_HASH_KEY, response);

    return response;
  } catch (e) {
    throw toFriendlyError(e);
  }
};

declare global {
  interface Window {
    Intercom: (
      id: string,
      settings?: IntercomSettings
    ) => {
      //
    };
    intercomSettings: IntercomSettings;
    installIntercom: (appId: string) => void;
  }
}

type IntercomSettings = {
  api_base: string;
  app_id: string;
  name?: string;
  user_id: string;
  user_hash?: string;
  email?: string;
  created_at?: number;
};

if (env.INTERCOM_ENABLED && window.installIntercom) {
  window.installIntercom(env.INTERCOM_APP_ID);
}

const INTERCOM_USER_HASH_KEY = "intercomUserHash";

import { parse } from "qs";
import { useMemo } from "react";

export const useParams = <T>() => {
  const { hash, search } = window.location;

  return useMemo(() => {
    return getParams<T>();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash, search]);
};

export const getParams = <T>() => {
  const { hash, search } = window.location;
  const hashParams = parse(hash.slice(1));
  const queryStringParams = parse(search.slice(1));

  return {
    ...hashParams,
    ...queryStringParams
  } as T;
};

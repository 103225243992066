import env from "@/env";
import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";
import { autocapturePlugin } from "@amplitude/plugin-autocapture-browser";
import { AnalyticsEventType } from "@/constants/analytics";

let isInitialised = false;

export const initialiseAmplitude = async () => {
  if (!env.FEATUREFLAG_AMPLITUDE_TRACKING_ENABLED) {
    return;
  }

  if (isInitialised) {
    return;
  }
  isInitialised = true;

  try {
    amplitude.init(env.AMPLITUDE_KEY);

    const sessionReplayTracking = sessionReplayPlugin();
    amplitude.add(sessionReplayTracking);
    amplitude.add(autocapturePlugin());
  } catch (e) {
    console.log(e);
  }
};

export const trackEvent = (eventName: AnalyticsEventType, options?: Record<string, unknown>) => {
  try {
    amplitude.track(eventName, {
      ...options,
      environment: env.ENVIRONMENT
    });
    console.log(`tracked event - ${eventName}`, options);
  } catch (e) {
    console.log("amplitude track event error", e);
  }
};

import { useUserStore } from "@/store/user.ts";

export const SoonLabel = () => {
  const theme = useUserStore((state) => state.theme);
  return (
    <span
      className={`border-gray-dark flex flex-row   items-center rounded-xs border px-[3px]  py-[1px] text-2xs opacity-50 ${
        theme === "dark" ? "text-white" : "text-gray-dark bg-gray-light"
      }`}
    >
      Soon
    </span>
  );
};

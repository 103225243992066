import env from "@/env";
import { toFriendlyError } from "@/utils/api";
import axios, { AxiosResponse } from "axios";

let token: string | undefined = undefined;

export const setToken = (newToken: string | undefined) => {
  token = newToken;
};

export const executeAPIRequest = async <T>({
  method,
  url,
  options
}: {
  method: "get" | "post" | "delete" | "put";
  url: string;
  options?: {
    data?: unknown;
    errorPrefix?: string;
    abortController?: AbortController;
    timeout?: number;
    headers?: Record<string, unknown>;
    publicError?: boolean;
  };
}) => {
  const forceFail = !url.startsWith("/auth/user-uuid");
  const { abortController, timeout, publicError } = options || {};
  const fullUrl = `${env.API_URL}${forceFail ? `${url}` : url}`;
  const headers = {
    ...options?.headers,
    Token: token
  };
  const config = {
    headers,
    signal: abortController?.signal,
    timeout: typeof timeout === "number" ? timeout : env.API_DEFAULT_TIMEOUT
  };

  try {
    let response: AxiosResponse<T>;

    switch (method) {
      case "delete":
        response = await axios.delete(fullUrl, config);
        break;

      case "put":
      case "post":
        response = await axios[method](fullUrl, options?.data, config);
        break;
      default:
        response = await axios.get(fullUrl, config);
        break;
    }

    return response.data as T;
  } catch (e) {
    if (publicError) {
      throw e;
    }
    throw toFriendlyError(e);
  }
};

import { NvidiaIcon } from "@/components/Icons/NvidiaIcon";
import { DeviceStatusType } from "@/utils/device";

export type MiddlewareUserStakedDeviceResponse = {
  id: number;
  stake_info_id: number;
  device_id: string;
  user_id: string;
  user_account: string;
  stake_amount: string;
  total_reward_amount: string;
  create_block_time: string;
  create_date: string;
  update_date: string;
  freeze_amount: string;
  freeze_end_time: string;
  withdrawable: string;
  status: string;
};

export type MiddlewareStakeEligibleDeviceResponse = {
  create_date: string;
  device_id: string;
  id: number;
  last_rewards_time: string | null;
  minimum_stake_amount: string;
  total_rewards: string;
  total_stake_account: string;
  total_stake_amount: string;
  update_date: string;
};

export type MiddlewareStakeEligibleDevice = {
  createDate: string;
  deviceId: string;
  deviceName: string;
  id: string;
  lastRewardsTime: string | null;
  minimumStakeAmount: number;
  totalRewards: number;
  totalStakeAccountCount: number;
  totalStakeAmount: number;
  updateDate: string;
};

export enum UserStakedDeviceStatusType {
  SUFFICIENT = "sufficient",
  INSUFFICIENT = "insufficient"
}

export enum StakeEventType {
  STAKED_DEVICE_UPDATED = "STAKED_DEVICE_UPDATED"
}

export enum ProxyToMiddlewareEndpoint {
  STAKE = "STAKE",
  UNSTAKE = "UNSTAKE",
  WITHDRAW = "WITHDRAW",
  INFO = "INFO"
}

export type UserStakedDevicesRequestOptions = {
  page?: number;
  limit?: number;
  status?: string;
  deviceId?: string;
};

export type UserStakedDeviceResult = {
  resultCount?: number;
  results: UserStakedDevice[];
  statuses: string[];
};

export type UserStakedDeviceResponse = {
  device_id: string;
  status: string;
  device_name: string;
  minimum_staked: number;
  hardware_quantity: number;
  hardware_name: string;
  brand_name: string;
  block_rewards: number;
  amount_staked: number;
  amount_frozen: number;
  amount_withdrawable: number;
  apr_estimate: number;
  freeze_end_time?: string;
  user_account?: string;
};

export type UserStakedDevice = {
  id: string;
  stakeInfoId?: number;
  deviceId: string;
  deviceName?: string;
  minimumStaked?: number;
  userId?: string;
  userAccount?: string;
  amountStaked: number;
  amountFrozen: number;
  amountWithdrawable: number;
  totalRewardAmount?: number;
  createBlockTime?: string;
  createDate?: string;
  updateDate?: string;
  freezeEndTime?: Date;
  status: UserStakedDeviceStatusType;
  blockRewards?: number;
  aprEstimate?: number;
  deviceStatus?: DeviceStatusType;
  hardwareQuantity: number;
  hardwareName: string;
  hardwareManufacturerColor: string;
  hardwareManufacturerIcon?: typeof NvidiaIcon;
  hardwareManufacturer: string;
  hasStakedMinimum: boolean;
  frozenTimeRemaining?: string;
  frozenTimeShortRemaining?: string;
  frozenTimeRemainingAsPercentage?: number;
  stakedWalletAddress?: string;
};

export const DISABLE_INCOMPLETE_FUNCTIONALITY = false;

export const BOX_SHADOW =
  "0px 0px 4px 0px rgba(16, 24, 40, 0.03), 0px 0px 8px 0px rgba(16, 24, 40, 0.04), 0px 0px 3px 0px rgba(0, 0, 0, 0.09)";

export const OPERATING_SYSTEM_OPTIONS = [
  {
    value: "mac",
    label: "Mac OS"
  },
  {
    value: "windows",
    label: "Windows"
  },
  {
    value: "linux",
    label: "Linux"
  }
];

export const DEFAULT_PAGINATION_LIMIT = 20;

export const DEFAULT_ROUTE_AFTER_LOGIN = "/id/account/io-elements";

export const REGISTRATION_ENABLED = false;

export const RESET_PASSWORD_ENABLED = false;

export const MAP_GLOBE_ENABLED = false;

export const REFRESH_REQUEST_ON_INTERVAL_DELAY = 1000 * 90;

export const REFRESH_INFERENCE_REQUEST_ON_INTERVAL_DELAY = 1000 * 10;

export const GRAPH_TYPE_TAB_ITEMS = [
  {
    value: "block_rewards",
    label: "Block Rewards"
  },
  {
    value: "earnings",
    label: "Earnings"
  }
] as {
  value: string;
  label: string;
}[];

export const DAILY_EARNING_GRAPH_DAYS_COUNT = 14;

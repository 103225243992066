import HotjarBrowser from "@hotjar/browser";
import { useEffect } from "react";
import env from "@/env";

export const Hotjar = () => {
  useEffect(() => {
    if (env.IS_CYPRESS) {
      return;
    }

    HotjarBrowser.init(SITE_ID, HOTJAR_VERSION);
  }, []);

  return null;
};

const SITE_ID = 3685343;
const HOTJAR_VERSION = 6;

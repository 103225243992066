import { useMainStore } from "@/store/main";
import { Link, useLocation } from "wouter";
import { SoonLabel } from "../SoonLabel/SoonLabel";
import { slugify } from "@/utils/string.ts";

export const MainMenu = () => {
  const tool = useMainStore((state) => state.tool);
  const [location] = useLocation();

  return (
    <div data-testid="MainMenu" className="flex flex-row overflow-x-auto">
      {tool.menuItems?.map((item) => {
        const { value, label, href, disabled } = item;
        const active = location.slice(0, href?.length) === href;

        return (
          <Link
            href={href}
            key={value}
            data-testid={`MainMenu_${slugify(value)}`}
            className={`group relative mx-1.5 mb-[-1px] flex flex-row items-center gap-2 rounded-t-md border-b-[3px] border-l border-r border-t border-white px-1.5 py-2 dark:mb-0 dark:border-transparent ${
              disabled
                ? `pointer-events-none opacity-20`
                : active
                  ? ` cursor-pointer border-b-gray-light border-l-gray-light-500 border-r-gray-light-500 border-t-gray-light-500 bg-gray-light  from-gray-light-600 to-gray-light  text-black hover:bg-gradient-to-b dark:border-b-white dark:bg-black dark:text-white dark:hover:from-black`
                  : `cursor-pointer border-b-gray-light-500 bg-white text-gray hover:text-black dark:border-none dark:border-b-gray-light-400 dark:bg-black  dark:hover:text-white`
            }`}
          >
            {/* <div className="flex w-[25px] flex-col items-center">
              {item.icon ? (
                <item.icon className={`h-[18px] dark:text-white ${active ? `` : ``}`} />
              ) : null}
            </div> */}
            <span className="whitespace-nowrap text-sm">{label}</span>
            {disabled ? <SoonLabel /> : null}
          </Link>
        );
      })}
    </div>
  );
};

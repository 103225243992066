import { TOOL_OPTIONS } from "@/constants/tool";
import { Tool } from "@/types";
import localCookies from "@/utils/cookie";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

type UseMainStoreProps = {
  tool: Tool;
  setTool: (tool: Tool) => void;
  getToolOptions: () => Tool[];
  updateToolToLocation: () => void;
};

export const useMainStore = create(
  persist<UseMainStoreProps>(
    (set, get) => ({
      tool: TOOL_OPTIONS[0],
      setTool: async (tool: Tool) => {
        set({ tool });
      },
      getToolOptions: () => {
        return TOOL_OPTIONS;
      },
      updateToolToLocation: () => {
        const { getToolOptions, setTool } = get();

        const toolOption = getToolOptions().find(({ href }) => {
          const section = href?.split("/")[1];
          const currentSection = window.location.pathname.split("/")[1];

          return section === currentSection;
        });

        if (toolOption) {
          setTool(toolOption);
        }
      }
    }),
    {
      name: "main",
      storage: createJSONStorage(() => localCookies),
      onRehydrateStorage: () => {
        return (state, error) => {
          if (error) {
            console.log("an error happened during hydration", error);
          } else if (state) {
            state.updateToolToLocation();
          }
        };
      }
    }
  )
);

import { Select, SelectContent, SelectItem, SelectValue } from "@/components/ui/select";
import * as SelectPrimitive from "@radix-ui/react-select";
import { ChevronDown } from "lucide-react";

export type OptionType = {
  label: string;
  value: string;
};

export type SelectInputProps = {
  value?: string;
  subLabel?: string;
  disabled?: boolean;
  name: string;
  options: OptionType[];
  onChange: (value: string) => void;
  placeholder?: string;
};

export default function SelectInput({
  onChange,
  value = "",
  options,
  disabled,
  name,
  subLabel,
  placeholder
}: SelectInputProps) {
  return (
    <div className="flex w-fit min-w-40 items-center rounded-[6px] border border-gray-dark-900 bg-gray-dark-600 px-3">
      <div className="pr-3 text-gray-dark-1500">{subLabel}</div>
      <Select
        defaultValue={value}
        onValueChange={(value: string) => onChange(value)}
        disabled={disabled}
        name={name}
      >
        <SelectPrimitive.Trigger
          className={`flex h-[40px] w-fit cursor-pointer items-center justify-between rounded-[0px] border-0 
            border-l-[1px] border-gray-dark-900 border-input bg-background bg-black px-3 py-2 pr-0 text-sm text-gray-dark-1000 ring-offset-background 
            placeholder:text-muted-foreground focus:outline-0
            disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 [&>span]:flex-1`}
        >
          <SelectValue placeholder={placeholder}>{value}</SelectValue>
          <div className="ml-3 flex h-[40px] min-w-6 items-center justify-center border-l-[1px] bg-gray-dark-600 pl-[10px]">
            <SelectPrimitive.Icon asChild>
              <ChevronDown className="color-gray-dark-1000 h-4 w-4" />
            </SelectPrimitive.Icon>
          </div>
        </SelectPrimitive.Trigger>
        <SelectContent className="w-fit min-w-[20px]">
          {options.map((item) => {
            return (
              <SelectItem
                className="flex items-center"
                value={item.value}
                key={`select_input_${item.value}`}
              >
                {item.label}
              </SelectItem>
            );
          })}
        </SelectContent>
      </Select>
    </div>
  );
}

import { parse } from "qs";

let hasRedirected = false;

export const redirectOnce = (url: string) => {
  if (hasRedirected) {
    return;
  }
  hasRedirected = true;
  window.location.href = url;
};

export const redirectUsingQuery = (defaultUrl: string) => {
  const parsed = parse(window.location.search.slice(1));
  return (parsed.redirect as string) ?? defaultUrl;
};

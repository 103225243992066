import { UserNotification } from "@/types";
import { formatDateTime } from "@/utils/date";

type NotificationItemType = {
  children?: React.ReactNode;
  notification: UserNotification;
};

export const NotificationItem = ({ notification, children }: NotificationItemType) => {
  return (
    <div className={`relative flex flex-col p-4 dark:bg-transparent`}>
      <div className="mb-2 flex flex-row items-center ">
        {notification.icon ? <notification.icon className="mr-2 h-[16px]" /> : null}
        <span className={`${notification.color} text-xs font-medium uppercase`}>
          {notification.type}
        </span>
        {children}
      </div>
      <div className="flex flex-col text-white max-lg:gap-2 lg:flex-row lg:items-end lg:justify-between">
        <div className="mr-4 text-sm capitalize">{notification.text}</div>
        <div className="text-xs">{formatDateTime(notification.createdAt)}</div>
      </div>
    </div>
  );
};

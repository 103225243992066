import { IconProps } from "@/components/Icons/index.ts";

export const ShapeCircleIcon: React.FC<IconProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_1012_7672)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.61067 5.00059C2.11549 5.88841 1.83334 6.91126 1.83334 8.00001C1.83334 8.76247 1.97172 9.4926 2.22472 10.1667H5.5939L4.49374 8.26152C4.48926 8.25421 4.48499 8.2468 4.48091 8.2393L2.61067 5.00059ZM2.20262 3.78591C1.34142 4.96864 0.833344 6.42499 0.833344 8.00001C0.833344 9.00597 1.04061 9.96353 1.41476 10.8323C1.42297 10.8557 1.43286 10.8783 1.44429 10.8999C2.44948 13.169 4.59812 14.8186 7.15924 15.1179C7.18492 15.1228 7.21078 15.1257 7.23659 15.1265C7.48743 15.1531 7.74213 15.1667 8.00001 15.1667C10.3457 15.1667 12.4283 14.0397 13.7356 12.2976C13.7593 12.2718 13.7799 12.2438 13.7974 12.2141C14.6586 11.0314 15.1667 9.57503 15.1667 8.00001C15.1667 6.99405 14.9594 6.03649 14.5853 5.16772C14.5771 5.14434 14.5672 5.12175 14.5557 5.10011C13.5505 2.83107 11.4019 1.18142 8.84078 0.882139C8.8151 0.877219 8.78924 0.874369 8.76344 0.873526C8.5126 0.846964 8.25789 0.833344 8.00001 0.833344C5.65428 0.833344 3.57176 1.96032 2.26437 3.70238C2.24072 3.72821 2.22009 3.75624 2.20262 3.78591ZM3.23612 4.08398L4.92006 7.00008L6.02592 5.08504C6.02645 5.08413 6.02698 5.08321 6.02751 5.0823L7.90323 1.83409C6.02365 1.863 4.34807 2.73284 3.23612 4.08398ZM9.01082 1.91578L7.32603 4.83334H9.53041C9.53651 4.83323 9.5426 4.83323 9.54868 4.83334H13.2926C12.38 3.31139 10.8296 2.21568 9.01082 1.91578ZM13.7753 5.83334H10.4061L11.5063 7.73847C11.5108 7.7458 11.515 7.75323 11.5191 7.76075L13.3894 10.9994C13.8845 10.1116 14.1667 9.08876 14.1667 8.00001C14.1667 7.23755 14.0283 6.50742 13.7753 5.83334ZM12.7639 11.916L11.08 8.99994L9.97402 10.9151C9.97355 10.9159 9.97307 10.9168 9.97259 10.9176L8.09679 14.1659C9.97637 14.137 11.6519 13.2672 12.7639 11.916ZM6.98921 14.0842L8.67399 11.1667H6.4696C6.46351 11.1668 6.45743 11.1668 6.45135 11.1667H2.70742C3.61999 12.6886 5.17044 13.7843 6.98921 14.0842ZM6.74866 10.1667H9.25146L10.5026 8.00009L9.25136 5.83334H6.74856L5.49744 7.99993L6.74866 10.1667Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1012_7672">
        <rect width="16" height="16" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

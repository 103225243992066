import { useCallback, useEffect, useState } from "react";
import { WarningBanner } from "../WarningBanner/WarningBanner";
import { useUserStore } from "@/store/user";
import { useLocation } from "wouter";

export const UserWarningViewer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const userId = useUserStore((state) => state.userId);
  const fetchUserInfo = useUserStore((state) => state.fetchUserInfo);
  const [message, setMessage] = useState<React.ReactNode>();
  const [location] = useLocation();
  const refreshUserInfo = useCallback(async () => {
    if (!userId) {
      return;
    }

    try {
      const userInfo = await fetchUserInfo();

      if (userInfo.blockRewardsSuspicious) {
        setMessage(
          <>
            Your account activity needs to be manually reviewed before block rewards distribution.
            Please reach out to our hotline Email:{" "}
            <a href="mailto:blockrewards@io.net" className="text-yellow">
              blockrewards@io.net
            </a>{" "}
            or create a support ticket on our intercom.
          </>
        );
        setIsOpen(true);
      }
    } catch (e) {
      console.log(e);
    }
  }, [fetchUserInfo, userId]);

  useEffect(() => {
    refreshUserInfo();
  }, [refreshUserInfo]);

  useEffect(() => {
    if (location === "/worker/earnings-and-rewards") {
      refreshUserInfo();
    }
  }, [location, refreshUserInfo]);

  return (
    <WarningBanner
      isOpen={isOpen}
      message={message}
      onClose={() => {
        setIsOpen(false);
      }}
    />
  );
};

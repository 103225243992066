import { CLUSTER_ITEMS } from "@/constants/cluster";
import { Cluster, ClusterType } from "@/types";
import { createContext } from "react";

export const getMaxDuration = (clusterType: ClusterType, durationType: string) => {
  switch (clusterType) {
    case ClusterType.KUBERNETES:
      switch (durationType) {
        case "hourly":
          return 336;
        case "daily":
          return 14;
        case "weekly":
          return 2;
      }
      break;
    default:
      switch (durationType) {
        case "hourly":
          return 60 * 24 * 30 * 4;
        case "daily":
          return 30 * 4;
        case "weekly":
          return 4;
      }
      break;
  }

  return Number.POSITIVE_INFINITY;
};

export const getDurationQuantity = (
  newDurationType: string,
  oldDurationType: string,
  oldDurationQuantity: number | undefined
) => {
  if (typeof oldDurationQuantity !== "number") {
    return;
  }

  const oldDurationQuantityInHours = getDurationQuantityInHours(
    oldDurationQuantity,
    oldDurationType
  );
  if (typeof oldDurationQuantityInHours !== "number") {
    return;
  }

  switch (newDurationType) {
    case "hourly":
      return oldDurationQuantityInHours;
    case "daily":
      return oldDurationQuantityInHours / 24;
    case "weekly":
      return oldDurationQuantityInHours / (24 * 7);
  }
};

const getDurationQuantityInHours = (durationQuantity: number, durationType: string) => {
  switch (durationType) {
    case "hourly":
      return durationQuantity;
    case "daily":
      return durationQuantity * 24;
    case "weekly":
      return durationQuantity * 24 * 7;
  }
};

export const getMinDuration = (clusterType: ClusterType, durationType: string) => {
  switch (clusterType) {
    case ClusterType.KUBERNETES:
      switch (durationType) {
        case "hourly":
          return 7 * 24;
        case "daily":
          return 7;
        case "weekly":
          return 1;
      }
  }

  return 0;
};

export const getClusterTypeFromUrl = (url: string) => {
  return CLUSTER_ITEMS.find((item) => {
    return item.url === url;
  })?.value as ClusterType;
};

export const getMaxHardware = (clusterType: ClusterType) => {
  switch (clusterType) {
    case ClusterType.KUBERNETES:
      return 20;
  }

  return Number.POSITIVE_INFINITY;
};

export const getMinHardware = (clusterType: ClusterType) => {
  switch (clusterType) {
    case ClusterType.RAY:
      return 4;
    case ClusterType.KUBERNETES:
      return 10;
  }

  return 1;
};

export const ClusterResultsDataTableContext = createContext<{
  setSelectedCluster: React.Dispatch<React.SetStateAction<Cluster | undefined>>;
  setSelectedAction: React.Dispatch<React.SetStateAction<string | undefined>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}>(undefined as any);

import { TOOL_OPTIONS } from "@/constants/tool";
import env from "@/env";
import { redirectOnce } from "@/utils/location";
import { getToolUrl } from "@/utils/tool";
import { useCallback } from "react";
import { BaseLocationHook, useLocation } from "wouter";

export const useToolLocation = () => {
  const [location, setLocation] = useLocation();
  const hook = useCallback(() => {
    return [
      location,
      (path: string) => {
        if (!env.SECTION_SUBDOMAINS_ENABLED || path.slice(0, 1) !== "/") {
          return setLocation(path);
        }

        const prefix = path.split("/")[1];
        const tool = TOOL_OPTIONS.find(({ value }) => {
          if (prefix === "login" && value === env.LOGIN_SUBDOMAIN) {
            return true;
          }

          return value === prefix;
        });

        if (!tool) {
          return setLocation(path);
        }

        const isValid = window.location.hostname.startsWith(tool.value);
        if (isValid) {
          return setLocation(path);
        }

        const newUrl = getToolUrl(tool.value, path.split("?")[0]);

        console.log("redirect as on wrong domain", `${newUrl}${window.location.search}`);
        redirectOnce(`${newUrl}${window.location.search}`);
      }
    ];
  }, [location, setLocation]) as BaseLocationHook;

  return hook;
};

import { IconProps } from "@/components/Icons/index.ts";

export const TelegramIcon: React.FC<IconProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M10.0008 18.3333C5.39166 18.3333 1.65833 14.6 1.65833 9.99996C1.65833 5.39163 5.38332 1.66663 9.99166 1.66663C14.5917 1.66663 18.325 5.39163 18.325 9.99996C18.325 14.6 14.5917 18.3333 9.99166 18.3333H10.0008ZM7.40916 10.975L7.41749 10.9666C7.89249 12.5583 8.13416 13.3583 8.13416 13.3583C8.22582 13.6166 8.35083 13.6583 8.50916 13.6416C8.65916 13.6166 8.74249 13.5333 8.84249 13.4333C8.84249 13.4333 9.16749 13.1083 9.82583 12.475L11.9508 14.0416C12.3342 14.25 12.6175 14.1416 12.7092 13.675L14.0842 7.14996C14.2342 6.54163 13.9675 6.29996 13.4925 6.49163L5.37583 9.61663C4.81749 9.83329 4.81749 10.1416 5.26749 10.2833L7.34249 10.925L7.40916 10.975Z"
      fill="currentColor"
    />
  </svg>
);

import { AuthToken } from "./auth";

export type UserMetaData = {
  io_id: string;
  name: string;
  solana_address: string;
  phone_code: string;
  phone_number: string;
  phone_number_code: string;
  phone_number_verified: boolean;
  is_provider: boolean;
  wallet_type: string;
  wallet_address: string;
  wallet_address_solana?: string;
  wallet_address_aptos?: string;
  wallet_verified: boolean;
  role: string;
  viewed_welcome_message?: boolean;
  viewed_io_elements_message?: boolean;
  picture: string;
};

export enum BalanceType {
  CLOUD = "cloud",
  WORKER = "worker",
  BC8 = "bc8",
  IOWORKER = "ioWorker",
  IOCLOUD = "ioCloud"
}

export enum NotificationType {
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error"
}

export enum CookiePreferenceType {
  FUNCTIONAL = "functional",
  ANALYTICS = "analytics",
  TRACKING = "tracking"
}

export type UserAuthToken = AuthToken & {
  "https://io.net/user": {
    created: boolean;
    io_id: string;
    primary_mail: string;
  };
};

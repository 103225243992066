import { UserMetaData } from "@/types/user";
import { Auth0CustomUserProfile } from "./auth0";
import { ALLOW_PROFILE_IMAGE_UPLOAD } from "@/constants/user";
import env from "@/env";

export const getUserPicture = (profile?: Auth0CustomUserProfile<UserMetaData>) => {
  if (!ALLOW_PROFILE_IMAGE_UPLOAD) {
    return DEFAULT_PROFILE_IMAGE;
  }
  return profile?.user_metadata.picture || DEFAULT_PROFILE_IMAGE;
};

const DEFAULT_PROFILE_IMAGE = "/assets/images/placeholder.jpg";

export const toStringWithLoginVersionSuffix = (key: string) => {
  if (!env.LOGIN_VERSION) {
    return key;
  }
  return `${key}_${env.LOGIN_VERSION.split(".").join("_")}`;
};
